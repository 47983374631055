body, html  {
  height: 100%;
  width: 100%;
  margin: 0%;
  padding: 0%;
  font-family: "Montserrat Light";
}

.loginpage {
    padding: 5%;
    text-align: center;
    position:fixed;
    width: 100%;
    height: 100%;
    background-image: url('../img/portalbackground-1.jpg');
    background-repeat: no-repeat ;
    background-attachment: fixed;
    background-size: cover; 
  }

.logo {
   float: right;
   padding: 5px;
   padding-right: 10%;
}

.loginform {
  border: 0px solid;
  border-radius: 2%;
   margin: auto;
   width: 400px;
    height: 400px;
    padding: 10px;
    margin-top: 10%;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 1.5), 0 6px 20px 0 rgba(0, 0, 0, 1.0);   
    background: linear-gradient(0deg, rgba(209,208,224,0.7) 0%, rgba(160, 163, 160, 0.365) 100%, rgba(0,212,255,0.1) 100%);
}

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1 !important;
    padding: 2px;
}

.inputlogin{
  color: rgb(5, 5, 5)  ;
  padding: 3%;
  border:none;
  background: linear-gradient(0deg, rgba(168, 168, 173, 0.7) 0%, rgba(236, 248, 236, 0.365) 100%, rgba(199, 218, 218, 0.9) 100%);
  border-bottom : 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 2%;
}

.loginform > h3 {
  font-size: 22px;
  color: black  ;
  margin: 35px 1px 1px 1px ;
  padding: 1%;
  font-weight: bold;
}

.login > button {
  margin-top: 8%;
  padding: 3%;
  width: 50% ;
}

.footer {
  padding: 5%;
  font-size:  10px;
  color: rgb(247, 250, 249);
}

.error {
  padding: 2%;
  font-size:  10px;
  color: rgb(230, 5, 5);
}

.dashboard {
  background-color: rgb(206, 209, 209);
}

.dashboard > .header {
  padding-top: 1%;
  padding-bottom: 0%;
}

.dashboard > .header > p {
  text-align: center;
}

.dashboard > .header > .logout {
  text-align: center;
  position: absolute;
  border-radius: 100px;
  width: 80px;
  height: 50px;
  top: 5%;
  right: 20%;
}

.dashboardfooter{
  font-size:  10px;
}

hr {
  border: 0;
	border-top: 5px solid #094CFA;
}

.documents > h3, .home > h3{
  text-align: center;
  color:black;
  border-bottom: 3px solid #0d0d0e;
}

.documents > .separator{
  background-color: #ffffff;
}

ul.separator {
  list-style-type: none;
  margin: 10px;
  padding: 1px;
  background-color: #868688 ;
}

ul.separator li{
  padding: 2%;
	border-bottom: 10px solid #262627;
  box-shadow: 0px 0px 5px 5px rgba(16, 77, 34, 0.4);
}

ul.separator li:hover{
  box-shadow: 0px 0px 5px 5px rgba(74, 151, 97, 0.4);
}

ul.separator li .dc{
  font-size: 20px;
  font-weight: bolder;
  text-align: left;
  width: auto;
  height: auto;
  padding-top: 0px;
  color: rgb(10,10,10);
}

ul.separator li .dcvalue{
  font-size: 24px;
  text-align: right ;
  color: #0c0c0c;
  font-weight: bold;
}

ul.separator li .dcs{
  font-size: 12px;
  text-align: left;
  font-weight: bold;
}

.access > h3{
  text-align: center;
  color:black;
  border-bottom: 3px solid #0d0d0e;
}

.schedule > h3{
  text-align: center;
  color:black;
  border-bottom: 3px solid #0d0d0e;
}

.dashboard > .header > .avatar {
  text-align: right;
  left: 50%;
  position: relative;
}

.dashboard > .header > h1 {
  font-size: 30px;
  color: black;
  text-align: center;
  padding: 3%;
}

.dashboard > .content {
  font-size: 16px;
  /* box-shadow: 1px 2px 3px 4px rgba(173, 173, 173, 0.4); */
  margin: 10px;
  padding: 2px;
  background-color: rgb(184, 184, 184);
  text-indent: 2px;
  text-justify: auto;
}

.dashboardText{
  font-weight: bold;
  padding-left : 12px ;
}

.nav-bar > ul > li{
  list-style-type : none;
}

.xisicons {
  font-family: xis-icons;
  font-size: 3rem;
  text-align: center;
  color: rgb(14, 4, 4);
  margin: 10px;
  border-radius: 6px;
  width: 150px;
  height: 130px;
}


.xisicons > p {
 font-size: 18px;
 color: white;
 font-family: "Montserrat Light" !important;
 text-align: center;
}

.ui-xis-home-icon-xis-home::before{content: "\e937";  color: white;}
.ui-xis-home-icon-config_4::before{content: "\e962"; color: white;}
.ui-xis-home-icon-xis-calendar::before{content: "\e91c";color: white; }
.ui-xis-home-icon-report::before{content: "\e96e";color: white;}
.ui-xis-home-icon-notification::before{content: "\e938";color: white;}

.xis-home{
  background-color: #FF6F6B ;
}

.xis-document{
  background-color: #00C3DA;
}

.xis-access{
  background-color: #CC5DC4;
}

.xis-reserve{
  background-color: #00B2A6 ;
}

.xis-notification{
  background-color: #FF7F5D;
}


.access > ul.separator {
  list-style-type: none;
  margin: 10px;
  padding: 1px;
  background-color: #ffffff ;
}

.my-custom-scrollbar {
  position: relative;
  height: 240px;
  overflow: auto;
  }

  .table-wrapper-scroll-y {
  display: block;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  scroll-behavior: auto;
}
.popup_inner {
  position: absolute;
  left: 15%;
  right: 15%;
  top: 3%;
  bottom: 5%;
  margin: auto;
  border-radius: 2%;
  background: rgb(176, 214, 193);
}

.popupButtons{
    float: right;
}

.popupCloseButton{
  margin: 0;
  position: absolute;
  top: 0%;
  left: 0%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* */
.popupTitle{
  padding: 15px;
  text-align: center;
  font-weight: bold;
}

.mealsInfo{
  background-color: #e4f5ee;
  font-size: 12px;
}

.popupSubTitles{
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
}

.popupTable{
  background-color: #84c0a7;
  color: white  ;
  margin: auto;
  width: 95%;
  border: 0.1px solid rgb(168, 170, 168);
  padding: 5px;
}

.card-columns {
    @include media-breakpoint-only(lg) {
    column-count: 4;
  }
    @include media-breakpoint-only(xl) {
    column-count: 5;
  }
}

.popup_innerMenu {
  left: 35%;
  right: 35%;
  bottom: 35%;
  background: rgb(173, 173, 173);
}


@media only screen and (max-width: 600px) {
  .loginform {
    width: 100% ;
  }

  .dashboard > .header > .avatar {
    left: 40%;
  }

  .dashboard > .header > .logout {
    top: 10%;
    right: 54%;
  } 
}