@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat'), local('Montserrat-Regular'),
        url(../fonts/montserrat.woff) format('woff'),
        url(../fonts/montserrat.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat Hairline';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Hairline'), local('Montserrat-Hairline'),
        url(../fonts/montserrat-hairline.woff) format('woff'),
        url(../fonts/montserrat-hairline.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat Light';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Light'), local('Montserrat-Light'),
        url(../fonts/montserrat-light.woff) format('woff'),
        url(../fonts/montserrat-light.ttf) format('truetype');
}

@font-face {
    font-family: 'xis-icons';
    src:  url('../fonts/xis-icons.eot?l0t058');
    src:  url('../fonts/xis-icons.eot?l0t058#iefix') format('embedded-opentype'),
      url('../fonts/xis-icons.ttf?l0t058') format('truetype'),
      url('../fonts/xis-icons.woff?l0t058') format('woff'),
      url('../fonts/xis-icons.svg?l0t058#xis-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}